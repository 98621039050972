.header-image-container {
  position: relative;
  height: 90vh;
  text-align: left;
  overflow: hidden;
  max-height: 100%;
  display: flex; /* Enable Flexbox */
  align-items: center; /* Center vertically */
  justify-content: flex-start; /* Center horizontally */
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 5% 15%;
  position: absolute;
  top: 0;
  left: 0;
}

/* Black overlay */
.header-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.335);
  z-index: 1;
}

.header-content {
  position: relative; /* Adjusted to relative */
  color: var(--permanent-dark-color);
  /* text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7); */
  z-index: 2;
  text-align: left; /* Center text */
  width: 80%;
  top: 20%;
  left: 5%;
}

.header-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  background-image: linear-gradient(to right, #ffffff00 0%, #ffffff77 5%, #f0f0f0 25%, #f0f0f0 75%, #ffffff77 95%, #ffffff00 100%);
  width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
}

.cta-buttons .btn {
  margin: 5px;
  font-weight: 700;
}

/* Custom styles for 'Place an Order' button */
.cta-buttons .btn-place-order {
  background-color: var(--permanent-white-color); /* Grey close to white */
  border: 2px solid #fff; /* Remove default border */
  color: var(--permanent-dark-color); /* Darker text color for contrast */
  border-top-right-radius: 10px; /* Rounded top-right corner */
  border-bottom-left-radius: 10px; /* Rounded bottom-left corner */
  border-top-left-radius: 0px; /* Rounded top-right corner */
  border-bottom-right-radius: 0px; /* Rounded bottom-left corner */
  padding: 10px 20px; /* Adequate padding for better button visibility */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.cta-buttons .btn-read-story {
  background: #ffffff36;
  border: 2px solid var(--permanent-white-color); /* Remove default border */
  color: var(--permanent-white-color); /* Darker text color for contrast */
  border-top-right-radius: 10px; /* Rounded top-right corner */
  border-bottom-left-radius: 10px; /* Rounded bottom-left corner */
  border-top-left-radius: 0px; /* Rounded top-right corner */
  border-bottom-right-radius: 0px; /* Rounded bottom-left corner */
  padding: 10px 20px; /* Adequate padding for better button visibility */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

/* Custom styles for 'Place an Order' button */
.cta-buttons .btn-place-order:hover,
.cta-buttons .btn-place-order:focus,
.cta-buttons .btn-place-order:active {
  background: #ffffff36 !important;
  border: 2px solid var(--permanent-white-color) !important; /* Remove default border */
  color: var(
    --permanent-white-color
  ) !important; /* Darker text color for contrast */
  box-shadow: none !important;
}

.cta-buttons .btn-read-story:hover,
.cta-buttons .btn-read-story:focus,
.cta-buttons .btn-read-story:active {
  background-color: var(
    --permanent-white-color
  ) !important; /* Grey close to white */
  border: 2px solid var(--permanent-white-color) !important; /* Remove default border */
  color: var(
    --permanent-dark-color
  ) !important; /* Darker text color for contrast */
  box-shadow: none !important;
}

.featured-artworks {
  background-color: var(--light-background-color);
  color: var(--text-darkonlightbg-color);
}

.custom-card {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  background: linear-gradient(
    to bottom,
    var(--wood-color-top) 60%,
    var(--wood-color-bottom) 100%
  );
  box-shadow: inset 0 2px 2px var(--inner-highlight),
    inset 0 -2px 2px var(--inner-shadow), 0 5px 10px var(--outer-shadow),
    0 -5px 10px var(--outer-highlight);
  padding: 20px; /* Size of the white border */
  border-radius: 0px !important; /* Adjust for desired rounded corners on the outer border */

  .card-image {
    position: relative;
    padding: 8px;
    background: var(--frame-border-color);
    border-radius: 0px; /* Internal card-image radius */

    img {
      border-radius: 0px !important;
    }

    .image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--overlay-background);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      color: #fff;
      border-radius: 0px !important;
    }

    .overlay-text {
      font-size: 20px;
    }
  }

  &:hover {
    transform: scale(1.05);
  }

  &:hover .image-overlay {
    opacity: 1;
  }
}
.featured-artworks .container {
  width: 80%;
}

.workshops {
  padding-top: 60px;
  background-color: var(--dark-background-color);
  color: var(--permanent-white-color);
}

.container-images {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Allows absolute positioning inside */
  margin-top: 20px;
  gap: 20px; /* Adds space between images */
}

.workshop-img {
  width: 280px;
  height: 400px;
  object-fit: cover;
  transition: transform 0.3s ease; /* Smooth transformation on hover */
  border-radius: 15px; /* Adds rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Adds shadow for depth */
}

.center-image {
  width: 340px;
  height: 480px;
  z-index: 2; /* Ensures it's on top */
}

.side-image {
  position: relative;
  z-index: 1; /* Lower z-index than center image */
}

/* Hover effects for interactivity */
.workshop-img:hover {
  transform: scale(1.05); /* Slightly enlarges the image */
  z-index: 3; /* Ensures the hovered image is above others */
}

.workshop-text p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1em; /* Adds spacing between paragraphs */
}

.workshop-text strong {
  font-weight: bold;
  color: var(--highlight-color); /* Adjust this color to fit your theme */
}

.workshop-text {
  margin: 20px 0; /* Adds vertical spacing around the text block */
  text-align: justify;
}

.container-text {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 10px;
  font-size: 1.15rem;
}


.testimonials {
  background-color: var(--light-background-color);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding-bottom: 40px;
  color: var(--text-darkonlightbg-color);
}

.testimonial-slider {
  width: 80%;
  margin: auto;
  padding-bottom: 30px;
  overflow: visible !important;
}
.testimonial-container {
  display: flex;
  width: 50%;
  min-height: 220px;
  max-width: 400px;
  flex-direction: column;
  align-items: center; /* Center align items for better control */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background: var(--dim-white-color);
  margin: 0px; /* Add margin for spacing between slides */
  position: relative; /* Needed for absolute positioning */
  overflow: visible !important; /* Allow items to overlap container if necessary */
  color: var(--dim-black-color);
}

.testimonial-container::before {
  content: "“";
  font-size: 80px;
  color: #231d1d;
  position: absolute;
  top: -35px;
  left: 10px;
  font-family: Arial, sans-serif;
  overflow: visible;
}

.testimonial-text {
  width: 85%; /* Adjust width to fit content */
  text-align: center; /* Center the text */
  position: relative; /* Stack order control */
  z-index: 1; /* Ensure text is above the image */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-evenly;
  min-height: 210px;
}

.client-details {
  font-weight: bold;
  display: block; /* Ensure it takes up full width for layout */
  margin-top: 2px; /* Space between review and client details */
}

.testimonial-image {
  width: 130px; /* Control image size */
  height: 130px;
  position: absolute; /* Absolute positioning */
  top: 50%; /* Center vertically */
  right: -65px; /* Half outside the container */
  transform: translateY(-50%); /* Align center with text vertically */
  z-index: 0; /* Below text */
}

.testimonial-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid var(--dim-white-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.services {
  background-color: var(--light-background-color);
  color: var(--text-darkonlightbg-color);
}

/* Services Section */
.services-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  padding: 20px 0;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.service-card {
  background: var(--dim-white-color);
  color: var(--dim-black-color);
  text-align: center;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  height: 100%;
  border-radius: 0 15px 0 15px;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.25);
}

.service-content {
  padding: 20px;
}

.service-image img {
  width: 100%;
  height: 200px; /* Fixed height for consistency */
  object-fit: cover; /* Ensures images cover the area nicely */
  border-top-right-radius: 15px;
}

.service-card h4 {
  margin-top: 0px;
  font-weight: 600;
}

.service-card ul {
  text-align: center;
  list-style: none; /* Remove default list styling */
  padding: 0;
  margin: 10px 0;
}

.service-card ul li {
  padding: 5px 0; /* Space out list items */
}

.service-card button {
  margin-top: auto; /* Pushes the button to the bottom */
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0 15px 0 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--permanent-dark-color); /* Grey close to white */
  border: 2px solid var(--permanent-dark-color); /* Remove default border */
  color: var(--permanent-white-color); /* Darker text color for contrast */
  border-top-right-radius: 10px; /* Rounded top-right corner */
  border-bottom-left-radius: 10px; /* Rounded bottom-left corner */
  border-top-left-radius: 0px; /* Rounded top-right corner */
  border-bottom-right-radius: 0px; /* Rounded bottom-left corner */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  font-weight: 500;
}

.service-card button:hover,
.service-card button:focus,
.service-card button:active {
  background-color: var(--permanent-white-color) !important; /* Slightly darker on hover for visual feedback */
  color: var(--permanent-dark-color) !important;
  border: 2px solid var(--permanent-dark-color) !important; /* Remove default border */
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .header-image-container {
    justify-content: center; /* Center horizontally */
  }

  .header-content {
    text-align: center;
    width: 90%;
    top: 20%;
    left: 0%;
  }

  .header-title {
    font-size: 2.0rem !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .custom-card {
    padding: 10px; /* Smaller padding on mobile */
  }

  .featured-artworks .container {
    width: 100%;
  }

  .header-image {
    object-position: 50% 15%;
  }

  .container-title {
    letter-spacing: 1px;
  }

  .workshops .container-images {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .workshop-img {
    width: 200px;
    height: 300px;
  }
  .center-image {
    width: 200px;
    height: 300px;
  }

  .services-container {
    grid-template-columns: 1fr; /* One column on small screens */
    width: 80%;
  }

  .service-card {
    margin-bottom: 20px; /* Adds space between cards on mobile */
  }

  .testimonial-container {
    flex-direction: column; /* Stack items vertically */
    width: 100%; /* Increase width for smaller screens */
    max-width: none; /* Remove max-width constraint */
    align-items: center; /* Center align items */
    margin-left: 0;
  }

  .testimonial-text{
    width: 100%;
  }

  .testimonial-image {
    position: relative; /* Switch from absolute to relative */
    width: 130px; /* Adjust width inside the container */
    height: 130px;
    right: 0; /* Reset right positioning */
    top: auto; /* Reset top positioning */
    transform: translateY(0px); /* Reset vertical translation */
    margin-top: 20px; /* Add some space between text and image */
    order: 3; /* Ensure the image comes last */
    margin-left: auto;
    margin-right: auto;
  }

  .testimonial-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .testimonial-text {
    order: 1; /* Text appears first */
  }

  .client-details {
    order: 2; /* Details appear after text */
    margin-bottom: 10px; /* Space before the image */
  }
}
