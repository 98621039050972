/* Admin.css */
.admin-container{
    background-color: var(--light-background-color);
    padding: 20px;
    color: var(--permanent-dark-color) !important;
}

.admin{
  background-color: var(--dim-white-color);
  border-radius: 10px;
  padding: 5px;
}

.add-new{
  margin-left: 10px;
  padding: 2px 6px !important;
  border-radius: 50% !important;
  border: 2px dashed var(--permanent-dark-color) !important;
  background-color: transparent !important;
  color: var(--permanent-dark-color) !important;
  font-size: 0.9rem !important;
}

.admin .nav-link{
  border-bottom: 1px solid #dee2e6; /* Adjust the color to match your theme */
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: var(--permanent-dark-color) !important;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--light-background-color);
  }
  
  .custom-table th {
    background-color: var(--light-background-color);
    text-align: left;
    padding: 10px;
  }
  
  .custom-table td {
    border: 1px solid var(--light-background-color);
    padding: 10px;
  }
  
  .action-button {
    /* Button styles */
    margin-right: 5px;
  }
  