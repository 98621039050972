
  .navbar{
    background-color: var(--navbar-background-color);
  }
  .navbar-brand{
    margin-top: 10px;
    margin-left: 20px;
  }
  .navbar-brand .title{
    font-family: 'Syne-Medium',sans-serif;
    font-size: 25px;
    font-weight: 500;
    color: var(--navbar-link-color);
  }
  .navbar-toggler{
    border: 0px !important;
  }

  .navbar-toggler{
    border: 0px !important;
  }

  .nav-link{
    padding-left: 20px !important;
    font-family: 'Syne-Regular',sans-serif;
    font-size: 16px;
    font-weight: 200;
    color: var(--navbar-link-color) !important;
    text-decoration: none !important;
    margin-left: auto;
    margin-right: auto;
  }

  .nav-link a{
    color: var(--navbar-link-color);
    text-decoration: none !important;
  }

  #basic-navbar-nav{
    margin-right: 20px;
  }

  .logo img{
    filter:var(--invert-filter);
}

  @media (max-width: 991px) {
    .logo img{
        width: 40px;
        height: 40px;
        filter:var(--invert-filter);
    }

    .navbar-brand .title{
        font-size: 22px;
    }

  .navbar-collapse {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:  var(--navbar-background-color); /* Add your desired background color */
    z-index: 1000;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around; 
    align-items: center;
  }

  .navbar-collapse.show {
    display: flex !important;
  }

  .nav-link{
    padding: 30px 0 30px 0 !important;
    flex-grow: 1 !important; /* Grow to take up equal space */
    text-align: center; /* Center align the text */
    border: none; /* Remove the red border used for testing */
    width: 100%; 
    display: block; 
  }

  .navbar-nav { 
    width: 100%;
    text-align: center;
  }
  .navbar-toggler{
    color: var(--navbar-link-color);
    filter:var(--invert-filter);
  }
}