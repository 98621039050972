/* CustomToast.css */
.custom-toast {
    position: fixed;
    z-index: 30;
    bottom: 20px;
    right: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px !important;
}

.custom-toast-header-success {
    background-color: var(--permanent-dark-color) !important;
    color: #fff !important;
}

.custom-toast-header-error {
    background-color: var(--error-red-color) !important;
    color: #fff !important;
}

.custom-toast-success {
    background-color: var(--permanent-dark-color);
    color: #fff;
}

.custom-toast-error {
    background-color: var(--error-red-color);
    color: #fff;
}

.custom-toast-body{
    background-color: var(--permanent-white-color);
    border-radius: 15px !important;
    color: var(--permanent-dark-color);
}