@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: 'Lora', serif;
}

.btn{
  border-radius: 0;
}

.btn-primary{
  background-color: #3b3b3b;
  color: #fff;
}

.btn-outline-primary{
  border-color: #3b3b3b;
  color: #3b3b3b;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus{
  border-color: #3b3b3b;
  background-color: #3b3b3b;
  color: #fff;
}

.toast-header .btn-close{
  color: #fff;
  background-color: #fff;
}