.Orders {
  background-color: var(--light-background-color);
  color: var(--text-darkonlightbg-color);
}
.recent-works {
  width: 95%;
  margin: auto;
}
.Slider {
  width: 90%;
  margin: 5%;
}

.slider-item {
  height: 400px;  /* Fixed height for uniformity */
  position: relative;  /* Allows absolute positioning of children */
  width: 100%;  /* Full width of the container, adjust as needed */
  overflow: hidden;  /* Hides any part of the image that exceeds the container */
}

.slick-slide {
  padding: 0 15px; /* Adds padding inside each slide */
}

.slider-image {
  width: 100%;  /* Makes the image take up the full width of its container */
  height: 100%;  /* Makes the image take up the full height of its container */
  object-fit: cover;  /* Ensures the image covers the area without distorting aspect ratio */
  position: absolute;  /* Aligns the image absolutely within its parent */
  top: 0;  /* Aligns the top of the image with the container */
  left: 0;  /* Aligns the left side of the image with the container */
}

.slider-text {
  position: absolute;
  bottom: 0;
  width: 100%;  /* Ensures the text covers the full width of the slider item */
  background-color: rgba(0, 0, 0, 0.7);  /* Semi-transparent overlay for readability */
  color: white;
  padding: 10px;
  text-align: center;  /* Centers the text horizontally */
}

.slider-title {
  font-size: 18px;
}

.badge{
  margin: 2px;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
  z-index: 5;
  background-color: #fff;
  padding: 15px 20px 15px 20px;
  border-radius: 50%;
}

.prev {
  left: 10px; /* Adjust the left position */
}

.next {
  right: 10px; /* Adjust the right position */
}

.order-place {
  width: 95%;
  margin: auto;
}
.highlight-img {
  border-radius: 10px;
  width: 250px;
  height: 250px;
  transition: transform 0.3s ease-in-out;
}

.highlight-img:hover {
  transform: translateY(-5px);
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.25);
}

.highlight-desc {
  text-align: center;
  font-style: italic;
  font-weight: 500;
  margin-bottom: 20px;
}

.form-group {
  margin-top: 10px;
}

.container-title {
  font-size: 25px;
  font-weight: 700;
}

.order-form-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.order-form-image-img {
  width: 600px;
  border-radius: 10px;
}

.order-process {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.steps-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.step {
  display: flex;
  align-items: center;
  position: relative;
}

.step-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--permanent-dark-color);
  color: var(--permanent-white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.step-line {
  height: 2px;
  background-color: #007bff;
  flex-grow: 1;
  margin: 0 10px;
}

.step-text {
  padding: 10px;
  text-align: center;
  font-weight: 500;
}

.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.order-type-card {
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  cursor: pointer;
  margin: 10px;
  transition: transform 0.3s ease;
  width: 120px !important;
  height: 80px !important;
  text-align: center; /* Centers text horizontally */
  flex-direction: column;
}

.order-type-card .card-body {
  padding: 0.5rem; /* Adjust padding as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensures it fills the card */
}

.order-type-card:hover {
  transform: scale(1.05);
}

.order-type-card.selected {
  background-color: var(--permanent-dark-color);
  color: var(--permanent-white-color);
}

.order-form {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.communication-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.communication-type-card {
  cursor: pointer;
  margin: 10px;
  transition: transform 0.3s ease;
  width: 120px !important;
  height: 55px !important;
  text-align: center;
}

.communication-type-card:hover {
  transform: scale(1.05);
}

.communication-type-card.selected {
  background-color: var(--permanent-dark-color);
  color: var(--permanent-white-color);
}

Form {
  width: 100%;
}

Form.Group {
  width: 100%;
}

.toast .btn-close {
  color: #fff;
}

.faq-section .faq-item {
  margin-bottom: 10px;
  border-radius: 15px;
}

.faq-section .faq-question {
  cursor: pointer;
  padding: 10px;
  background-color: var(--permanent-white-color);
  color: var(--permanent-dark-color);
  font-weight: 500;
  border: 1px solid #ddd;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: 0.3s ease;
}

.faq-section .faq-answer {
  background-color: var(--dim-white-color);
  color: var(--permanent-dark-color);
  font-weight: 400;
  border-top: none;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  transition: 0.3s ease;
}

.faq-section .faq-question.open {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.faq-section .faq-answer.open {
  max-height: 300px; /* Adjust as needed */
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.faq-answer-text {
  padding: 10px;
}

@media screen and (max-width: 1100px) {
  .slider-item {
    height: 350px;
  }
  
  .slick-slide {
    padding: 0 5px; /* Adds padding inside each slide */
  }

  .order-form {
    width: 95%;
  }

  .order-form-container {
    flex-direction: column;
    align-items: center;
  }

  .order-form-image-img {
    width: 300px;
  }

  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .communication-card-container {
    flex-direction: column;
    align-items: center;
  }

  .order-type-card {
    width: 250px !important;
    height: 50px !important;
  }

  .step {
    justify-content: center !important;
    flex-direction: column;
    padding-top: 5px;
  }

}
