@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("/src/styles/color_variable.css");

@font-face {
  font-family: "Syne-Medium"; /* Give the font a name */
  src: url("../public/assets/Syne-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Syne-Bold"; /* Give the font a name */
  src: url("../public/assets/Syne-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Syne-Regular"; /* Give the font a name */
  src: url("../public/assets/Syne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Syne-SemiBold"; /* Give the font a name */
  src: url("../public/assets/Syne-SemiBold.ttf") format("truetype");
}

html {
  color-scheme: light dark;
}

body {
  font-family: "Syne-Regular", sans-serif;
  background: var(--light-background-color) !important;
  background-color: var(--light-background-color) !important;
}
.content-container {
  margin-left: 0px;
  margin-top: 80px !important;
  margin-right: 0px;
}

.theme-switcher {
  position: fixed;
  bottom: 20px; /* Distance from the bottom */
  left: 20px; /* Distance from the left side */
  width: 50px; /* Diameter of the circle */
  height: 50px; /* Diameter of the circle */
  background-color: var(--button-bg-color); /* Background color of the button */
  color: var(--button-text-color); /* Text/icon color of the button */
  border: none;
  border-radius: 50%; /* Makes it perfectly circular */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for better visibility */
  z-index: 1000; /* Ensures it's above other content */
  opacity: 0.3;
  transition: opacity 0.3s ease, background-color 0.3s ease;
}

.theme-switcher:focus {
  outline: none; /* Removes outline on focus for a cleaner look */
  opacity: 1;
}

.theme-switcher:hover {
  background-color: var(--button-hover-bg-color); /* Color change on hover */
  opacity: 1;
}

.padded-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
}
.container-title {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  padding-bottom: 20px;
  font-family: "Syne-Bold", sans-serif;
  transform: scaleY(1.2);
  letter-spacing: 1.2px;
}

.container-buttons .btn-container-action,
.btn-container-action {
  background-color: var(--permanent-dark-color); /* Grey close to white */
  border: 2px solid var(--permanent-dark-color); /* Remove default border */
  color: var(--permanent-white-color); /* Darker text color for contrast */
  border-top-right-radius: 10px; /* Rounded top-right corner */
  border-bottom-left-radius: 10px; /* Rounded bottom-left corner */
  border-top-left-radius: 0px; /* Rounded top-right corner */
  border-bottom-right-radius: 0px; /* Rounded bottom-left corner */
  padding: 10px 20px; /* Adequate padding for better button visibility */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  font-weight: 700;
}

.container-buttons .btn-container-action-inverted {
  background: none; /* Grey close to white */
  border: 2px solid var(--permanent-white-color); /* Remove default border */
  color: var(--permanent-white-color); /* Darker text color for contrast */
  border-top-right-radius: 10px; /* Rounded top-right corner */
  border-bottom-left-radius: 10px; /* Rounded bottom-left corner */
  border-top-left-radius: 0px; /* Rounded top-right corner */
  border-bottom-right-radius: 0px; /* Rounded bottom-left corner */
  padding: 10px 20px; /* Adequate padding for better button visibility */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  font-weight: 700;
}

.container-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.container-buttons .btn-container-action:hover,
.container-buttons .btn-container-action:focus,
.container-buttons .btn-container-action:active {
  background-color: var(
    --permanent-white-color
  ) !important; /* Slightly darker on hover for visual feedback */
  color: var(--permanent-dark-color) !important;
  border: 2px solid var(--permanent-dark-color) !important; /* Remove default border */
  box-shadow: none !important;
}

.container-buttons .btn-container-action-inverted:hover,
.container-buttons .btn-container-action-inverted:focus,
.container-buttons .btn-container-action-inverted:active {
  background-color: #fff !important; /* Grey close to white */
  border: 2px solid #fff !important; /* Remove default border */
  color: var(
    --permanent-dark-color
  ) !important; /* Darker text color for contrast */
  box-shadow: none !important;
}

.container-buttons-inverted {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.container-buttons-inverted .btn-container-action:hover {
  background-color: #fff; /* Slightly darker on hover for visual feedback */
  color: #231d1d;
  border: 2px solid #231d1d; /* Remove default border */
}
.sparkling-area {
  position: absolute; /* Default to absolute to not interfere */
  top: 0;
  left: 0;
  width: 100%; /* Default to 0 width */
  height: 100%; /* Default to 0 height */
  z-index: 10000; /* Keep it behind everything when not active */
  overflow: hidden;
}

/* Apply overlay and make visible only when sparkling */
.sparkling {
  position: fixed !important; /* Cover the entire screen when active */
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.616) !important; /* Dark overlay */
  z-index: 10000 !important; /* Ensures it's on top when active */
  pointer-events: auto !important; /* Allow interaction with the sparkles */
  overflow: hidden;
}

/* Styles for individual sparkles using a custom image */
.sparkle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url('../public/assets/sparkle.png'); /* Check this path */
  background-size: cover;
  animation: sparkle-animation 1.5s ease-in-out forwards;
}

/* Keyframes for sparkle animations */
@keyframes sparkle-animation {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}