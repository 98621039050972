/* color_variables.css */

:root {
  --background-color: #ffffff; /* Light theme background */
  --text-color: #333333; /* Light theme text color */
  --navbar-background-color: #ffffff;
  --navbar-link-color: #181818;
  --permanent-white-color: #ffffff;
  --permanent-dark-color: #181818;
  --light-background-color: #dbcfcf;
  --dark-background-color: #3a3636;
  --dim-white-color: #f0f0f0;
  --dim-black-color: #282828;
  --text-darkonlightbg-color: #282828;
  --invert-filter: none;
  --footer-invert-filter: invert(90%);
  --button-bg-color: #fff; /* Light background for the button in light mode */
  --button-text-color: #000; /* Dark text color in light mode */
  --button-hover-bg-color: #e0e0e0; 
  --error-red-color: #a40909;

  /* frame */
  --wood-color-top: #472828fa;
  --wood-color-bottom: #100c0a;
  --inner-highlight: rgba(255, 255, 255, 0.1);
  --inner-shadow: rgba(0, 0, 0, 0.4);
  --outer-shadow: rgba(0, 0, 0, 0.4);
  --outer-highlight: rgba(255, 255, 255, 0.4);
  --frame-border-color: white;
  --overlay-background: rgba(0, 0, 0, 0.692);
}

[data-theme="dark"] {
  --background-color: #333333; /* Dark theme background */
  --text-color: #ffffff; /* Dark theme text color */
  --navbar-background-color: #181818;
  --navbar-link-color: #fff;
  --permanent-white-color: #ffffff;
  --permanent-dark-color: #181818;
  --light-background-color: #434040;
  --dark-background-color: #282828;
  --dim-white-color: #cac6c6;
  --dim-black-color: #282828;
  --text-darkonlightbg-color: #ffffff;
  --error-red-color: #a40909;
  /* frame */
  --wood-color-top: #e8e8e8; /* Light wooden color top */
    --wood-color-bottom: #c2c2c2; /* Light wooden color bottom */
    --inner-highlight: rgba(220, 220, 220, 0.2); /* Slightly more visible highlight */
    --inner-shadow: rgba(45, 45, 45, 0.7); /* Darker inner shadow for depth */
    --outer-shadow: rgba(0, 0, 0, 0.8); /* Very dark outer shadow for a striking effect */
    --outer-highlight: rgba(220, 220, 220, 0.5); /* Visible but subtle outer highlight */
    --frame-border-color: #f7f7f4; /* Dark grey frame border */
    --overlay-background: rgba(255, 255, 255, 0.1); /* Lighter overlay for contrast */
    --invert-filter: invert(100%);
    --footer-invert-filter: invert(90%);
    --button-bg-color: #333; /* Dark background for the button in dark mode */
    --button-text-color: #fff; /* Light text color in dark mode */
    --button-hover-bg-color: #555;
}

[data-theme="surprise"] {
  --background-color: linear-gradient(120deg, #FFDE03 20%, #F9F871 80%); /* Bright yellow gradient */
  --text-color: #806000; /* Dark mustard for contrast */
  --navbar-background-color: #f7e588; /* Sunflower yellow */
  --navbar-link-color: #405D27; /* Leaf green for links */
  --permanent-white-color: #FFFFFF; /* Pure white for clean contrasts */
  --permanent-dark-color: #593315; /* Dark brown like sunflower seeds */
  --light-background-color: #FFFACD; /* Lemon chiffon for light areas */
  --dark-background-color: #c2a858; /* Sand dune, a muted earth tone */
  --dim-white-color: #FFFFE0; /* Light yellow for soft accents */
  --dim-black-color: #484830; /* Olive drab for subdued dark elements */
  --text-darkonlightbg-color: #3F6212; /* Deep forest green for text */
  --invert-filter: none;
  --footer-invert-filter: invert(30%);
  --button-bg-color: #FFAA1D; /* Vibrant yellow for buttons */
  --button-text-color: #453821; /* Dark brown for button text */
  --button-hover-bg-color: #CCB974; /* Khaki for button hover background */
  --error-red-color: #8B0000; /* Dark red for error states */
  --wood-color-top: #A0522D; /* Sienna for a warm, wooden feel */
  --wood-color-bottom: #DEB887; /* Burly wood, lighter and inviting */
  --inner-highlight: rgba(255, 255, 224, 0.6); /* Light yellow for a bright inner highlight */
  --inner-shadow: rgba(128, 128, 0, 0.5); /* Olive for an earthy inner shadow */
  --outer-shadow: rgba(139, 69, 19, 0.8); /* Saddle brown for deep external shadows */
  --outer-highlight: rgba(222, 184, 135, 0.6); /* Burly wood for a glowing external highlight */
  --frame-border-color: #fff; /* Golden rod for a glowing frame border */
  --overlay-background: rgba(184, 134, 11, 0.3); /* Dark goldenrod for a soft overlay */
}


