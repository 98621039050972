.AdminLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full screen height */
  background-color: var(--light-background-color);
}

.login-form {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  width: 100%;
  max-width: 400px; /* Max width for the form */
}

.login-form h2 {
  text-align: center;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #666; /* Dark gray color for text */
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd; /* Light gray border */
  border-radius: 4px; /* Slightly rounded corners for the input */
  box-sizing: border-box; /* Include padding and border in the width */
}

.error {
  color: red; /* Red color for error messages */
  font-size: 0.9em;
  margin-top: 10px;
  text-align: center;
}

.login-button {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: var(
    --permanent-white-color
  ) !important; 
  color: var(--permanent-dark-color) !important;
  border: 2px solid var(--permanent-dark-color) !important;
  box-shadow: none !important;
}
