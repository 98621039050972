.works{
  background-color: var(--light-background-color);
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
}
  .workDetails{
    padding-top: 20px;
  }

  .workDetails-description{
    padding: 10px 0px 0px 0px;
  }

  .workDetails-instaLink{
    padding-top: 10px;
  }

  .workDetails-instaLink a{
    text-decoration: none;
  }

  .workDetails-instaLink i{
    padding-right: 5px;
  }

  .work-custom-card {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  
    .work-card-image {
      position: relative;
  
      .work-image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.692);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        color: #fff;
      }
  
      .work-overlay-text {
        font-size: 20px;
      }
    }
  
    &:hover {
      transform: scale(1.05);
    }
  
    &:hover .work-image-overlay {
      opacity: 1;
    }
  }
  
  .custom-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #000;
  }
  