.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .spinner {
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    background-image: url('/public/assets/loader.png'); /* Path to your logo */
    background-size: cover;
    animation: flip 2s linear infinite;
}

@keyframes flip {
    0% { transform: rotateY(0deg); }
    50% { transform: rotateY(180deg); }
    100% { transform: rotateY(360deg); }
}
  