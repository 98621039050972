.wave-transition {
  /* background: url("/public/assets/wave.svg"); */
  height: 100%; /* Match SVG height */
  width: 100%;
}

.Footer {
  position: relative;
  margin-bottom: 0;
  padding-top: 80px;
  bottom: 0;
  overflow: visible;
  background-color: var(--dark-background-color);
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: auto;
}

.footer-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 40px;
  margin-top: -20px;
  margin-bottom: 20px;
  padding: 20px;
  flex: 1;
}

.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 40px;
  margin-top: -20px;
  margin-bottom: 20px;
  flex: 1;
  padding-left: 20px;
}

.footer-design-trademark {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.footer-design {
  margin-bottom: 5px !important;
}

.footer-follow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  white-space: nowrap;
  margin-bottom: 10px;
  position: relative;
}

.footer-quicklink {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  white-space: nowrap;
  flex-direction: row;
}

.social-icon img {
  width: 30px; /* Set based on your preference */
  height: auto;
}

/* .footer-design {
    text-align: center;
    margin-bottom: 20px;
    align-items: center; 
    justify-content: center;
    display: flex;
}

.footer-trademark {
    display: flex;  
    align-items: center;
    justify-content: center;
    padding: 10px 0; 
    white-space: nowrap;
    flex-direction: column;
} */

.footer-logo {
  display: flex;
  align-items: center; /* Ensures the logo is vertically centered */

  img {
    filter: var(--footer-invert-filter);
    margin: auto;
    display: block;
  }
}

/* Styling for text within the footer */
.footer-text {
  font-size: 18px; /* Adjust font size as needed */
  font-weight: 500;
  color: var(
    --dim-white-color
  ) !important; /* Light grey color for subtle text */
  margin: 0; /* Removes default margins */
  padding-left: 15px !important;
  padding-bottom: 0px;
  flex-grow: 1;
  text-align: center;
}

.footer-image-container {
  position: absolute;
  top: 0%;
  bottom: 0%; /* Adjust as needed to let it overflow above */
  left: 45%; /* Center align the image */
  transform: translateX(-45%); /* Ensure it's perfectly centered */
  z-index: 10; /* Higher z-index to ensure it's above other elements */
}

.footer-image {
  height: auto; /* Maintain aspect ratio */
  bottom: 0px; /* Align to the bottom */
  position: absolute;
  width: 150px;
  animation: glow 3s ease-in-out infinite;
}

@keyframes glow {
    0%, 100% {
        filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.3)) drop-shadow(0 0 6px rgba(255, 255, 255, 0.2)) drop-shadow(0 0 8px rgba(255, 255, 255, 0.1));
    }
    50% {
        filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.5)) drop-shadow(0 0 12px rgba(255, 255, 255, 0.3)) drop-shadow(0 0 16px rgba(255, 255, 255, 0.2));
    }
}

.footer-nav {
  z-index: 20; /* Ensures nav is above the image if they overlap */
  display: flex; /* Aligns the list items horizontally */
  list-style-type: none; /* Removes bullets */
  margin: 0; /* Removes default margin to align properly */
  align-items: center; /* Centers the items vertically */
  justify-content: flex-end; /* Aligns the navigation to the right */
}

.footer-nav-item {
  margin-left: 20px; /* Adds spacing between each link */
}
/* Additional specific styles for links to increase accessibility */
.footer-nav-link {
  font-size: 16px;
  color: var(--dim-white-color) !important; /* Slightly darker grey for links */
  text-decoration: none !important; /* Removes underline from links */
  padding: 10px 0px 10px 10px; /* Provides padding around links for easier clickability */
  transition: color 0.3s; /* Smooth transition for hover effect */
}

.footer-nav-link:hover {
  color: #fff !important; /* Changes color on hover */
}

/* Specific style for the year and copyright text */
.mb-0 {
  font-weight: bold; /* Makes the text bold */
  font-size: 18px; /* Larger text for emphasis */
}

.social-icon {
  display: flex; /* Flexbox to center content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 40px; /* Fixed width */
  height: 40px; /* Fixed height, equal to width to form a circle */
  color: var(--dim-white-color); /* Icon color */
  padding: 10px; /* Padding around the icon */
  border-radius: 50%; /* Rounded border to form a circle */
  border: 1px solid var(--dim-white-color); /* Border color */
  margin: 0 10px; /* Margin on left and right for spacing */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions for hover effects */
}

.social-icon:hover {
  background-color: var(--dim-white-color); /* Background color on hover */
  color: #000; /* Icon color change on hover */
  transform: scale(1.1); /* Slightly enlarge icon on hover */
  cursor: pointer; /* Change cursor to pointer to indicate clickability */
}

.tooltip-text {
  visibility: hidden;
  width: 160px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  left: 110%; /* Position to the right of the image */
  margin-left: 10px; /* Spacing between image and tooltip */
  opacity: 0;
  transition: opacity 0.6s;
}

/* Tooltip arrow */
.tooltip-text::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* At the left side of the tooltip */
  margin-top: -5px; /* Center the arrow vertically */
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black; /* Arrow pointing to the left */
}

/* Show the tooltip text when you mouse over the tooltip container */
.footer-image-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .Footer {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .footer-left,
  .footer-right {
    align-items: center;
    text-align: center;
    width: 100%; /* Ensure full width for better alignment */
    margin: 0;
    padding: 0;
  }

  .footer-left {
    order: 2;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
  }

  .footer-right {
    order: 1;
    justify-content: center;
    margin-right: 0px;
  }

  .footer-follow {
    justify-content: center;
    flex-direction: row;
    width: 100%;
  }

  .footer-nav {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin-top: 10px; /* Additional space above for clarity */
  }

  .footer-nav-item {
    margin: 5px; /* Provides even spacing around each nav item */
  }

  .footer-logo {
    margin: 0;
    padding: 0;
    order: 3;
    margin-top: 20px;
  }

  .footer-design-trademark {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px; /* Space after the logo for clear separation */
    margin: 0%;
    padding-left: 0;
  }

  .footer-design,
  .footer-trademark {
    width: 100%;
    text-align: center;
    padding-left: 0;
  }

  .footer-text {
    padding: 0 !important;
    margin: 0;
    margin-top: 20px;
    font-size: 16px;
  }

  .footer-image-container {
    position: static;
    order: 3; /* Image appears at the bottom */
    transform: none;
    padding-top: 20px;
    margin: auto;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    height: max-content;
  }

  .footer-image {
    position: relative;
    bottom: 0px;
    left: 20px;
    height: max-content;
  }
}

