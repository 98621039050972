.PrivacyPolicy {
  background-color: var(--light-background-color);
  color: var(--text-darkonlightbg-color);
}

.pdf-iframe {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
}
