.new-work{
    background-color: var(--light-background-color);
    padding: 10px;
}

.form-label{
    padding-top: 20px !important;
    font-weight: 600;
    color: var(--text-darkonlightbg-color) !important;
}

.form-control{
    background-color: var(--dim-white-color) !important;
}
.image-preview {
  width: 100%; /* or a fixed width if you prefer */
  max-width: 150px; /* Adjust as needed */
  max-height: 200px; /* Adjust as needed */
  object-fit: contain; /* Keeps the aspect ratio without cropping the image */
  margin-top: 10px; /* Adds some space above the image */
  border-radius: 5px; /* Optional: Rounds the corners of the image */
}
